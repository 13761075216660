import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode
} from "react";

// Define the types for the context value
interface ProjectIdContextType {
  projectId: string | null;
  setProjectId: (id: string | null) => void;
}

// Define the type for the children prop in provider
interface ProjectIdProviderProps {
  children: ReactNode;
}

// Create context with an undefined default value
const ProjectIdContext = createContext<ProjectIdContextType | undefined>(
  undefined
);

// Create provider component
export const ProjectIdProvider: React.FC<ProjectIdProviderProps> = ({
  children
}) => {
  // Use a function to initialize state to avoid accessing localStorage on every render
  const [projectId, setProjectId] = useState<string | null>(() => {
    return localStorage.getItem("projectId");
  });

  // Sync projectId with local storage
  useEffect(() => {
    if (projectId === null) {
      localStorage.removeItem("projectId");
    } else {
      localStorage.setItem("projectId", projectId);
    }
  }, [projectId]);

  return (
    <ProjectIdContext.Provider value={{ projectId, setProjectId }}>
      {children}
    </ProjectIdContext.Provider>
  );
};

// Create a custom hook to use the context
export const useProjectId = (): ProjectIdContextType => {
  const context = useContext(ProjectIdContext);
  if (!context) {
    throw new Error("useProjectId must be used within a ProjectIdProvider");
  }
  return context;
};
