import React from "react";
export type UtilityProviderValues = {
  isPageLoading: boolean;
  selectedProjectMenu: string;
  projectId: string;
  storeIsPageLoading: (value: boolean) => void;
  storeSelectedProjectMenu: (value: string) => void;
  storeProjectId: (value: string) => void;
};
const initState = {
  isPageLoading: false,
  selectedProjectMenu: "",
  projectId: "",
  storeIsPageLoading: (value: boolean) => void value,
  storeSelectedProjectMenu: (value: string) => void value,
  storeProjectId: (value: string) => void value
};
const UtilityContext = React.createContext<UtilityProviderValues>(initState);
export const UtilityProvider = UtilityContext.Provider;
type Props = {
  children?: React.ReactNode;
};
export const UtilityProviderContainer: React.FC<Props> = ({ children }) => {
  const [isPageLoading, setIsPageLoading] = React.useState<boolean>(
    initState.isPageLoading
  );
  const [selectedProjectMenu, setSelectedProjectMenu] =
    React.useState<string>("");

  const [projectId, setProjectId] = React.useState<string>("");

  const storeIsPageLoading = (value: boolean) => {
    setIsPageLoading(value);
  };

  const storeSelectedProjectMenu = (value: string) => {
    setSelectedProjectMenu(value);
  };

  const storeProjectId = (value: string) => {
    setProjectId(value);
  };
  return (
    <UtilityProvider
      value={{
        isPageLoading,
        projectId,
        selectedProjectMenu,
        storeIsPageLoading,
        storeSelectedProjectMenu,
        storeProjectId
      }}
    >
      {children}
    </UtilityProvider>
  );
};
export default UtilityContext;
