import { ProjectPayloadInterface } from "interfaces/project";
import React from "react";
export type ProjectProviderValues = {
  listOfProject: ProjectPayloadInterface[];
  selectedProject: ProjectPayloadInterface | null;
  storeListOfProject: (value: ProjectPayloadInterface[]) => void;
  storeSelectedProject: (value: ProjectPayloadInterface) => void;
};
const initState = {
  listOfProject: [],
  selectedProject: null,
  storeListOfProject: (value: ProjectPayloadInterface[]) => void value,
  storeSelectedProject: (value: ProjectPayloadInterface) => void value
};
const ProjectContext = React.createContext<ProjectProviderValues>(initState);
export const ProjectProvider = ProjectContext.Provider;
type Props = {
  children?: React.ReactNode;
};
export const ProjectProviderContainer: React.FC<Props> = ({ children }) => {
  const [listOfProject, setListOfProject] = React.useState<
    ProjectPayloadInterface[]
  >(initState.listOfProject);

  const [selectedProject, setSelectedProject] =
    React.useState<ProjectPayloadInterface | null>(initState.selectedProject);

  const storeListOfProject = (value: ProjectPayloadInterface[]) => {
    setListOfProject(value);
  };

  const storeSelectedProject = (value: ProjectPayloadInterface) => {
    setSelectedProject(value);
  };
  return (
    <ProjectProvider
      value={{
        listOfProject,
        selectedProject,
        storeListOfProject,
        storeSelectedProject
      }}
    >
      {children}
    </ProjectProvider>
  );
};
export default ProjectContext;
