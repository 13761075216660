// StockStickersContext.tsx

import React, { createContext, useContext, useState, ReactNode } from "react";

interface StockStickersContextProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  data: any; // Replace 'any' with the actual data type if available
  setData: React.Dispatch<React.SetStateAction<any>>;
  isGetting: boolean;
  setIsGetting: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccess: boolean;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  isError: boolean;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
}

const StockStickersContext = createContext<
  StockStickersContextProps | undefined
>(undefined);

export const StockStickersProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [value, setValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [data, setData] = useState<any>(null); // Replace 'any' with the actual data type if available
  const [isGetting, setIsGetting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  return (
    <StockStickersContext.Provider
      value={{
        value,
        setValue,
        pageNumber,
        setPageNumber,
        data,
        setData,
        isGetting,
        setIsGetting,
        isSuccess,
        setIsSuccess,
        isError,
        setIsError
      }}
    >
      {children}
    </StockStickersContext.Provider>
  );
};

export const useStockStickers = () => {
  const context = useContext(StockStickersContext);
  if (!context) {
    throw new Error(
      "useStockStickers must be used within a StockStickersProvider"
    );
  }
  return context;
};
