import React, {
  createContext,
  useContext,
  useRef,
  useState,
  ReactNode
} from "react";
import { fabric } from "fabric";

interface CanvasContextType {
  canvasRef: React.MutableRefObject<fabric.Canvas | null>;
  selectedShapeColor: string;
  setSelectedShapeColor: (color: string) => void;
  addText: (textColor: string) => void;
}

const CanvasContext = createContext<CanvasContextType | undefined>(undefined);

export const useCanvas = () => {
  const context = useContext(CanvasContext);
  if (!context) {
    throw new Error("useCanvas must be used within a CanvasProvider");
  }
  return context;
};

export const CanvasProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [selectedShapeColor, setSelectedShapeColor] =
    useState<string>("#000000");
  const canvasRef = useRef<fabric.Canvas | null>(null);

  const addText = (textColor: string) => {
    if (!canvasRef.current) return;
    const text = new fabric.Textbox("New Text", {
      left: 100,
      top: 100,
      width: 200,
      fontSize: 20,
      fill: textColor,
      hasControls: true,
      hasBorders: true,
      selectable: true
    });
    canvasRef.current.add(text);
    canvasRef.current.setActiveObject(text);
  };

  return (
    <CanvasContext.Provider
      value={{ canvasRef, selectedShapeColor, setSelectedShapeColor, addText }}
    >
      {children}
    </CanvasContext.Provider>
  );
};
