import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ModalProviderContainer } from "context/modalContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Toaster } from "react-hot-toast";
import { GOOGLE_AUTH_KEY } from "utils/constants";
import { UserProviderContainer } from "context/userContext";
import { CanvasProviderContainer } from "context/canvasContext";
import { UtilityProviderContainer } from "context/utilityContext";
import { ProjectProviderContainer } from "context/projectContext";
import { ProjectIdProvider } from "./context/projectIdContext";
import { CanvasProvider } from "./components/CanvasContainer/newCanvasMenus/canvasContext";
import { StockStickersProvider } from "./context/stickerContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const siteKey =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
  "6LfxdxgqAAAAAOAfbFLENKv4NXeF-UjjhInrdXCh";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_AUTH_KEY}>
      <CanvasProvider>
        <ProjectProviderContainer>
          <UtilityProviderContainer>
            <CanvasProviderContainer>
              <ModalProviderContainer>
                <UserProviderContainer>
                  <ProjectIdProvider>
                    <StockStickersProvider>
                      <GoogleReCaptchaProvider reCaptchaKey={siteKey || ""}>
                        <App />
                      </GoogleReCaptchaProvider>
                    </StockStickersProvider>
                  </ProjectIdProvider>
                </UserProviderContainer>
              </ModalProviderContainer>
            </CanvasProviderContainer>
          </UtilityProviderContainer>
        </ProjectProviderContainer>
      </CanvasProvider>
    </GoogleOAuthProvider>
    <Toaster />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
