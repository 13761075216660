import { UserProfileInterface } from "interfaces/user";
import React from "react";

export type UserProviderValues = {
  userInfo: UserProfileInterface;
  profileImage: string;
  storeUserInfo: (value: UserProfileInterface) => void;
  storeProfileImage: (value: string) => void;
};

const initState = {
  userInfo: {} as UserProfileInterface,
  profileImage: "",
  storeUserInfo: (value: UserProfileInterface) => void value,
  storeProfileImage: (value: string) => void value
};

const UserContext = React.createContext<UserProviderValues>(initState);
export const UserProvider = UserContext.Provider;

type Props = {
  children?: React.ReactNode;
};

export const UserProviderContainer: React.FC<Props> = ({ children }) => {
  const [userInfo, setUserInfo] = React.useState<UserProfileInterface>(
    initState.userInfo
  );

  const [profileImage, setProfileImage] = React.useState<string>("");

  const storeUserInfo = (value: UserProfileInterface) => {
    setUserInfo(value);
  };

  const storeProfileImage = (value: string) => {
    setProfileImage(value);
  };

  return (
    <UserProvider
      value={{
        userInfo,
        profileImage,
        storeUserInfo,
        storeProfileImage
      }}
    >
      {children}
    </UserProvider>
  );
};

export default UserContext;
